import React from 'react';

import { css } from '@emotion/core';
import { useShallow } from 'zustand/react/shallow';

import { Spacer } from '@soomo/lib/components/pageElements';
import { figureStyles } from '@soomo/lib/components/pageElements/Figure';
import { BaseTextStyles } from '@soomo/lib/components/pageElements/Text/styles';

import { studentHelpUrl, instructorHelpUrl } from './constants';
import { useStudyStackStore } from './store';
import * as baseStyles from './styles';

const title = 'How to Practice in Study Stack';
const vimeoUrl =
	'https://player.vimeo.com/video/925853848?h=b046dbdb7c&title=0&byline=0&portrait=0&autopause=0&app_id=122963';
const credits = `Vimeo video. https://vimeo.com/925853848/b046dbdb7c. Uploaded March 21, 2024, by Soomo. To activate captions, click the CC button in the embedded player, then select English. For a text transcript, follow the link below.`;
const transcriptUrl =
	'https://s3.amazonaws.com/assets.soomopublishing.com/Screencasts/How_to_Practice_in_Study_Stack_Transcript.docx';
const width = 640;
const height = 360;

const NoStudyStackQuestions: React.VFC = () => {
	const userRole = useStudyStackStore((state) => state.userRole);

	return (
		<>
			<h1 css={baseStyles.h1}>Study Stack</h1>

			<div css={BaseTextStyles}>
				<p>
					You don’t have any Study Stack questions yet. As you complete questions in your webtext,
					practice questions will be added here.
				</p>

				<figure css={figureStyles}>
					<div css={iframeWrapperStyles}>
						<iframe
							src={vimeoUrl}
							width={width}
							height={height}
							allowFullScreen
							css={iframeStyles}></iframe>
					</div>
					<div className="figure-caption">
						<p className="figure-caption-credit">{credits}</p>
						<p className="figure-caption-transcript">
							<a aria-label={`Read text transcript for video: ${title}`} href={transcriptUrl}>
								Read Text Transcript
							</a>
						</p>
					</div>
				</figure>

				<Spacer />

				<p>
					For more information about how Study Stack works, visit our{' '}
					<a
						href={userRole === 'instructor' ? instructorHelpUrl : studentHelpUrl}
						target="_blank"
						rel="noreferrer">
						Help Center
					</a>
					.
				</p>
			</div>
		</>
	);
};

export default NoStudyStackQuestions;

const iframeWrapperStyles = css`
	width: 100%;
	height: 100%;
	position: relative;
	margin-bottom: 15px;
	padding-top: ${(height / width) * 100}%;
`;

const iframeStyles = css`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: none;
`;
