import React, { useMemo } from 'react';

import { useRollbarPerson } from '@rollbar/react';
import { useShallow } from 'zustand/react/shallow';

import AriaLiveAnnouncer from '@soomo/lib/components/AriaLiveAnnouncer';
import { Indeterminate } from '@soomo/lib/components/Loaders';

import { studentHelpUrl, instructorHelpUrl } from './constants';
import ErrorNoToken from './ErrorNoToken';
import ErrorTokenExpired from './ErrorTokenExpired';
import ErrorUnknown from './ErrorUnknown';
import Header from './Header';
import useActionCable from './hooks/useActionCable';
import useFullStory from './hooks/useFullStory';
import useIntercom from './hooks/useIntercom';
import useLongLivedJwt from './hooks/useLongLivedJwt';
import useLostResponsesWarning from './hooks/useLostResponsesWarning';
import MCQuestionPoolList from './MCQuestionPoolList';
import NoStudyStackQuestions from './NoStudyStackQuestions';
import { useStudyStackStore } from './store';
import * as styles from './styles';

import './global.css';

function App() {
	const { userId, courseId, questionPools, appLoadingState, userRole } = useStudyStackStore(
		useShallow((state) => ({
			userId: state.userId,
			courseId: state.courseId,
			questionPools: state.questionPools,
			appLoadingState: state.appLoadingState,
			userRole: state.userRole
		}))
	);
	useRollbarPerson({
		userId,
		courseId
	});
	useLongLivedJwt();
	useActionCable();
	useLostResponsesWarning();
	useFullStory();
	useIntercom();

	const content = useMemo(() => {
		if (appLoadingState === 'ready' && questionPools.length === 0) {
			return <NoStudyStackQuestions />;
		}

		if (appLoadingState === 'loading' || appLoadingState === 'ready') {
			return (
				<>
					<h1 css={styles.h1}>Study Stack</h1>
					<div css={styles.bodyText}>
						<p>
							Practice recalling the concepts and principles covered in this webtext by generating a
							stack of study questions.
						</p>
						<p>
							Questions will become available to study once you’ve answered them in the webtext.
							Your answers here in Study Stack will not affect your grade. For more information
							about how Study Stack works, visit our{' '}
							<a href={userRole === 'instructor' ? instructorHelpUrl : studentHelpUrl} target="_blank" rel="noreferrer">
								Help Center
							</a>
							.
						</p>
					</div>

					<hr css={styles.hr} />

					<h2 css={styles.controlsHeader}>Which chapter would you like to study?</h2>

					{appLoadingState === 'loading' ? (
						<div css={styles.loader}>
							<Indeterminate />
							<span>Loading your chapters...</span>
						</div>
					) : (
						<MCQuestionPoolList />
					)}
				</>
			);
		}

		if (appLoadingState === 'error-no-token') return <ErrorNoToken />;
		if (appLoadingState === 'error-token-expired') return <ErrorTokenExpired />;
		if (appLoadingState === 'error-unknown') return <ErrorUnknown />;
	}, [appLoadingState, questionPools]);

	return (
		<div css={styles.root}>
			<Header />

			<main css={styles.main}>{content}</main>

			<AriaLiveAnnouncer />
		</div>
	);
}

export default App;
